import { Marque } from "../components/Marque";
import { ReverseMarque } from "../components/ReverseMarque";
import { Preview } from "../components/Preview";
import { Footer } from "../components/Footer";
import  ImageSlider  from "../components/ImageSlider";
import { Header } from "../components/Header";
import  Video  from "../components/Video";
import HomeBannerOverlay from "../components/HomeBannerOverlay";

const urls = [
    "https://ik.imagekit.io/dnddecpho/projects/Razak_City_Sungai_Besi_1.jpg_-_1601870728_eetz0_vNs0uT",
    "https://ik.imagekit.io/dnddecpho/projects/Emerald-9-Area-Outlook.jpg_-_1601869725_1mUglqrG4NT8",
    "https://ik.imagekit.io/dnddecpho/projects/Nexus___Bouvelard___Kajang.jpg_-_1601870061__1-BnoZ_QWj",
    "https://ik.imagekit.io/dnddecpho/projects/MKH_Boulevard_II_Kajang.jpg_-_1601870473_OZXZ0VnmO",
    "https://ik.imagekit.io/dnddecpho/projects/Mirai___Kajang.jpg_-_1601870963_PJfizM5RtoA",
    "https://ik.imagekit.io/dnddecpho/projects/Platz___Setapak_2.jpg_-_1601871420_9xHNsi4oJ"
]


const Home = () => {
    return (
        <div  className="top-0" style={{background: 'url("https://dndpropertygrp.com/wp-content/uploads/2020/04/DD_ImagesForDEv13.png")', backgroundSize: "cover" ,backgroundPosition: "center" }}>
            <Header />
            <HomeBannerOverlay imageUrl="https://ik.imagekit.io/dnddecpho/dndream/bgproject_UlxbKFd-j.jpg?updatedAt=1710473718425" title="D&Dream Property"></HomeBannerOverlay>
            <Video url="https://ik.imagekit.io/dnddecpho/dndream/D&D%20Property%20Group%20_%E4%B8%80%E6%9D%A1%E5%BF%83_%20Team%20Video_UWHa7GbMq.mp4?updatedAt=1706100567979"></Video>
            <Marque />
            <ImageSlider images={urls} />
            <ReverseMarque />
            <Preview />
            <Footer />
        </div>
    );
}

export default Home;
