import React, { useState } from "react";
import PropTypes from "prop-types";

interface ImageSliderProps {
    images: string[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 < images.length ? prevIndex + 1 : 0
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex - 1 >= 0 ? prevIndex - 1 : images.length - 1
        );
    };

    return (
        <div className="relative">
            <div
                className="lg:flex hidden overflow-hidden"
            >
                {Array.from({ length: 3 }).map((_, index) => {
                    const imageIndex = (currentIndex + index) % images.length;
                    const image = images[imageIndex];

                    return (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-1/3 flex-shrink-0 h-60vh"
                        />
                    );
                })}
            </div>
            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-1 rounded-md"
            >
                &#8249;
            </button>
            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-1 rounded-md"
            >
                &#8250;
            </button>

            <div
                className="flex lg:hidden overflow-hidden"
            >
                {Array.from({ length: 1 }).map((_, index) => {
                    const imageIndex = (currentIndex + index) % images.length;
                    const image = images[imageIndex];

                    return (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className="w-full flex-shrink-0 h-40vh"
                        />
                    );
                })}
            </div>
            <button
                onClick={prevSlide}
                className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-1 rounded-md"
            >
                &#8249;
            </button>
            <button
                onClick={nextSlide}
                className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white px-3 py-1 rounded-md"
            >
                &#8250;
            </button>
        </div>
    );
};

ImageSlider.propTypes = {
    images: PropTypes.array.isRequired,
};

export default ImageSlider;
