export const ReverseMarque = () => {
    return (
        <section>
            <div style={{ WebkitTextStrokeColor: "#0000004d", WebkitTextStrokeWidth: "1px", color: "rgb(250 230 1/var(--tw-text-opacity))", fontFamily: "fatfrank,Comic Sans MS,sans-serif" }}
            className="text-opacity-100 bg-mainColor text-3xl p-2 justify-center items-center">
                <div className="relative flex overflow-x-hidden">
                    <div className="animate-marquee3 uppercase whitespace-nowrap">
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                    </div>

                    <div className="absolute uppercase animate-marquee4 whitespace-nowrap">
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                        <span className="text-3xl mx-1">· Award-Winning Real Estate Partner </span>
                    </div>
                </div>
            </div>
        </section>
    );
};
